body {
  background-color: black;
}

a {
  text-decoration: none;
}

.App.page-loaded {
  background-color: initial;
}

.headshot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  scale: 0.2;
}

@media (min-width: 1000px) {
  .mobile-banner {
    display: none;
  }

  .desktop-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    scale: 0.8;
  }
  .clips {
    display: none;
  }
}

.background {
  background-image: url("../public/seanrad-eastville.JPG");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-attachment: fixed;
  height: 100vh;
}

.baller-video {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  text-align: center;
  color: whitesmoke;
  z-index: 3;
}

.main-body h1 {
  margin-bottom: -20px; /* Adjust this value as needed */
}

.main-body p {
  font-size: 24px;
  padding-left: 300px;
  padding-right: 300px;
  line-height: 30px;
}
.highlight {
  color: #35c1a1;
}

.tight5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  color: whitesmoke;
  background-image: url("../public/5minbackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-attachment: fixed;
  height: 100vh;
}

.stmarks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  color: whitesmoke;
  background-image: url("../public/5minbackground-night.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-attachment: fixed;
  height: 100vh;
}

.janitor-manager {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  color: whitesmoke;
  margin-bottom: -10px;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: whitesmoke;
}

/* Media Queries for Mobile */
@media (max-width: 1000px) {
  .mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .mobile-banner {
    z-index: 1;
  }

  .desktop-banner {
    display: none;
  }

  .background {
    background-image: url("../public/seanrad-eastville.JPG");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    background-attachment: inherit;
    height: 70vh;
    width: 100vw;
  }

  .baller-video {
    z-index: 1;
  }

  .main-body {
    color: whitesmoke;
    z-index: 2;
    display: none;
  }

  .clips {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;

    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    margin-top: -18px;
    text-shadow: black 0.1em 0.1em 0.2em;
  }

  a {
    color: white;
    text-decoration: underline;
  }
  a:hover {
    color: yellow;
  }

  .clips h1 {
    font-size: 14px;
    text-decoration: underline;
    padding-top: 20px;
  }

  .about {
    color: whitesmoke;
    margin-left: 25px;
    margin-right: 25px;
  }

  .about p {
    font-size: 24px;
  }

  /* .clips h1:hover {
    color: yellow;
  } */

  .tight5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    color: whitesmoke;
    background-image: url("../public/5minbackground.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    background-attachment: fixed;
    display: none;
  }
  .tight5mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .stmarks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    color: whitesmoke;
    background-image: url("../public/5minbackground-night.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    background-attachment: fixed;
    display: none;
  }

  .janitor-manager {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    color: whitesmoke;
    margin-bottom: -10px;
    scale: 0.7;
    display: none;
  }

  .janitor-manager-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .icons {
    gap: 10px;
    scale: 0.5;
    opacity: 80%;
  }
}
